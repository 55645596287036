<template>
    <NetList :roles="roles"></NetList>
</template>

<script>
    import NetList from '@/components/NetList.vue'
    export default {
        components: {
            NetList,
        },
        data() {
            return {
                roles:'user'
            }
        },
        async created() {
            
        },

        methods: {
            
        },
    }
</script>

<style lang="scss" scoped>
    .code-mirror-div {
        padding: 2px;
        height: 500px;

        .tool-bar {
            top: 20px;
            margin: 30px 2px 0px 20px;
        }
    }
</style>