<template>
    <v-container>
        <v-card>
            <v-card-title>
                {{roles=='admin'?'平台虚拟网':'首页'}}
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col sm="3" v-if="roles!='admin'">
                        <v-card class="mx-auto" outlined hover height="100%" to="/net_detail_add" style="display:flex;align-items:center;">
                            <div style="margin:0 auto">
                                <div style="text-align:center">
                                    <v-icon size="48" color="blue darken-2">mdi-plus</v-icon>
                                </div>
                                <v-card-subtitle style="text-align:center">
                                    添加虚拟网
                                </v-card-subtitle>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col v-for="(item,index) in networkList" :key="index" cols='12' sm="3">
                        <v-card class="mx-auto" outlined hover>
                            <v-card-title>
                                <span>{{item.name}}</span>
                                <v-spacer></v-spacer>
                                <v-btn icon color="warning" @click="del(item)">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-card-text>
                                <div>TOKEN:{{item.nwid}}</div>
                            </v-card-text>
                            <v-divider class="mx-4"></v-divider>
                            <v-card-text>
                                <div style="width:90px;">
                                    <v-switch v-model="item.enabled" label="状态" @change="changeStatus(item)"></v-switch>
                                </div>
                                <div class="d-flex justify-space-between mb-6">
                                    <span>创建时间:</span>
                                    <span class="text-right">{{$moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}}</span>
                                </div>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="getQrcode(item)" text> 二维码 </v-btn>
                                <v-btn color="primary" @click="detail(item)" text>详情</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-row>
            <v-dialog v-model="qrDialog" max-width="500px" persistent>
                <v-card>
                    <v-card-title>
                        <span class="headline">生成二维码</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <div style="margin:0 auto;text-align:center;width:100%">
                                <qrcode-vue size="200" :value="qrcodeValue"></qrcode-vue>
                            </div>
                        </v-container>
                        <v-card-title>{{value}}</v-card-title>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="qrDialog = false">取消</v-btn>
                        <v-btn color="blue darken-1" text @click="qrDialog = false">确定</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>

</template>

<script>
    // @ is an alias to /src
    import { post, tips, obj } from '@/facade';
    import QrcodeVue from 'qrcode.vue'
    import { encode, decode } from 'js-base64';
    export default {
        props: ['roles'],
        components: {
            QrcodeVue,
        },
        data() {
            return {
                loading: true,
                singleExpand: true,
                form: {},
                networkList: [],
                qrDialog: false,
                qrcodeValue: '',
                value: ""
            }
        },
        async created() {
          this.form.uid=this.roles=='admin'?0:obj.uid
            await this.getNetworkList()
        },

        methods: {
            // 获取虚拟网列表
            async getNetworkList() {
                const data = await post('/vnet/network/list', this.form)
                if (data.code == 'ok') {
                    this.loading = false;
                    this.networkList = [...data.networks];
                } else {
                    this.loading = false;
                    tips('error', data.message)
                }
            },
            /**删除*/
            async del(item) {
                const value = await confirm('即将删除，是否继续')
                if (value) {
                    const data = await post('/vnet/network/delete', { nid: item.id })
                    if (data.code == 'ok') {
                        tips('success', '删除成功')
                        this.getNetworkList()
                    } else {
                        tips('error', data.message)
                    }
                }

            },
            /**改变虚拟网状态 */
            async changeStatus(item) {
                const value = await confirm('即将更改状态，是否继续')
                if (value) {
                    const form = { nid: item.id, enabled: !!item.enabled }
                    const data = await post('/vnet/network/update', form)
                    if (data.code == 'ok') {
                        tips('success', '更改成功')
                        this.getNetworkList()
                    } else {
                        tips('error', data.message)
                        this.getNetworkList()
                    }
                } else {
                    this.getNetworkList()
                }

            },
            //生成二维码
            async getQrcode(item) {
                console.log(item)
                const form = {
                    id: item.nwid,
                    icon: "vpn_lock",
                    name: item.name,
                    net: { nwid: item.nwid },
                    api:"https://v2.beikevip8.com/vnet/app/gw"
                }
                this.value = encode(JSON.stringify(form));
                this.qrcodeValue = encode(JSON.stringify(form))
                this.qrDialog = true;
            },
            //详情
            detail(item) {
                localStorage.setItem('netInfo', JSON.stringify(item))
                localStorage.setItem('scene', this.roles)
                this.$router.replace({ path: '/net_layout'})
            }

        },
    }
</script>

<style lang="scss" scoped>
    .code-mirror-div {
        padding: 2px;
        height: 500px;

        .tool-bar {
            top: 20px;
            margin: 30px 2px 0px 20px;
        }
    }
</style>